var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table-simple',{staticClass:"text-left",attrs:{"striped":"","bordered":"","dark":""}},[_c('b-tbody',_vm._l((Object.entries(_vm.data).filter(
        function (e) { return _vm.hide == null || !_vm.hide.includes(e[0]); }
      )),function(ref){
      var k = ref[0];
      var v = ref[1];
return _c('b-tr',{key:k},[_c('b-td',{staticStyle:{"width":"1%"}},[_vm._v(_vm._s(k))]),(!_vm.allowClick)?_c('b-td',{class:k == 'features' ? 'p-0' : ''},[(_vm.isLink(k) && v != null)?_c('a',{staticClass:"ws-pre",attrs:{"href":_vm.getLink(k),"target":"_blank"}},[_vm._v(_vm._s(_vm.vv(v)))]):(_vm.isColor(k) && v != null)?_c('span',[_c('div',{staticClass:"ws-pre",style:({
              '--color': v.toString().startsWith('#')
                ? v
                : '#' + v.toString(16),
            })}),_vm._v(" "+_vm._s(_vm.vv(v)))]):(_vm.isTable(k) && v != null)?_c('span',{staticClass:"ws-pre"},[_c('data-table',{attrs:{"data":_vm.getTableData(k, v),"hide":['features']}})],1):_c('span',{staticClass:"ws-pre"},[_vm._v(_vm._s(_vm.vv(v))+_vm._s(_vm.ve(k, v)))])]):_c('b-td',[_c('a',{staticClass:"ws-pre",attrs:{"href":"#"},on:{"click":function($event){return _vm.$emit('value-clicked', k)}}},[_vm._v(_vm._s(_vm.vv(v)))])])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }